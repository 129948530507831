import { storyblokEditable, useStoryblokState } from "gatsby-source-storyblok"

import Layout from "../components/structural/layout"
import PropTypes from "prop-types"
import React from "react"
import Seo from "../components/seo"
import SmartText from "../utils/TextHandler"
import { graphql } from "gatsby"

const Legal = props => {
  const { data, pageContext } = props

  const story = useStoryblokState(
    data?.storyblokEntry ? data.storyblokEntry : pageContext.story
  )

  const { content } = story

  return (
    <Layout>
      <div {...storyblokEditable(content)} className={`mx-auto`}>
        <SmartText containerClassName="px-6 legal-text">
          {content?.content}
        </SmartText>
      </div>
    </Layout>
  )
}

export default Legal

Legal.defaultProps = {
  data: null,
  pageContext: null,
}

Legal.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
}

export const Head = ({ data, pageContext }) => {
  const story = useStoryblokState(
    data?.storyblokEntry ? data.storyblokEntry : pageContext.story
  )

  const { seo, seo_author, seo_image } = story.content

  const siteSettings = JSON.parse(data?.settings?.content)

  return (
    <Seo
      settings={siteSettings}
      {...seo}
      author={seo_author}
      image={seo_image}
    />
  )
}

export const QUERY = graphql`
  query PageQuery {
    settings: storyblokEntry(full_slug: { eq: "site-settings" }) {
      content
      name
      full_slug
      uuid
      id
      internalId
    }
  }
`
